.check_container {
    display: flex;
    flex-direction: column;
}

.cart_summary {
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--color-white);
    margin-bottom: 20px;
    margin-top: 80px;
}
.inner-cart_summary {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}
.inner-cart_summary h3 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}
.cart-item {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid var(--color-grey);
    margin-bottom: 10px;
    border-radius: 15px;
}
.order_check-title {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    min-width: 150px;
    width: 100%;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}
.count_price-check p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}
.cart-item-image {
    width: 100px;
    margin-right: 20px;
}

.checkout_form {
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--color-white);
}
.inner_form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.inner_form h2,
.inner_form h3 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}
.user_data-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item_data {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
}
.item_data:last-child {
    margin: 0;
}
.item_data span {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}
.item_data input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    border: 1px solid var(--color-black);
    border-radius: 5px;
    font-family: var(--font);
    font-size: var(--size-14);
}
.item_data input::placeholder {
    font-family: var(--font);
    font-size: var(--size-12);
}

.user_delivery-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
}
.delivery_title {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-top: 40px;
}
.delivery_item {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.delivery_item:last-child {
    margin: 0;
}
.delivery_item span {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}
.delivery_item input,
.delivery_item select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    border: 1px solid var(--color-black);
    border-radius: 5px;
    font-family: var(--font);
    font-size: var(--size-14);
}
.delivery_item select {
    font-family: var(--font);
    font-size: var(--size-14);
}
.delivery_item input::placeholder {
    font-family: var(--font);
    font-size: var(--size-12);
}
.delivery_courier {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.payment_options {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.payment_options label {
    margin-bottom: 10px;
}
.payment_options label span {
    margin-left: 10px;
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}

.total_price-btn__item {
    margin-top: 30px;
}
.total-price {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
}
.confirm-btn {
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
    padding: 5px 15px;
    margin-top: 20px;
}


.order-confirmation {
    padding: 20px 10px;
}
.order-confirmation h2 {
    font-size: var(--size-16);
    color: var(--color-black);
    font-weight: var(--medium);
    margin-bottom: 20px;
}
.order-confirmation h3 {
    font-size: var(--size-16);
    color: var(--color-black);
    font-weight: var(--medium);
    margin: 20px 0 10px 0;
}
.order-confirmation p {
    font-size: var(--size-14);
    color: var(--color-black);
    line-height: 1.2;
}