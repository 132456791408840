.review_container {
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--color-white);
    margin-bottom: 20px;
    margin-top: 20px;
    box-sizing: border-box;
}
.inner_review {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}
.rev_title {
    font-size: var(--size-20);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}

.inner_review form {
    display: flex;
    flex-direction: column;
}
.inner_review form textarea {
    width: 100%;
    display: block;
    padding: 5px 3px;
    border: 1px solid var(--color-black);
    border-radius: 5px;
    height: 100%;
    min-height: 140px;
    box-sizing: border-box;
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-black);
    margin-bottom: 20px;
}
.inner_review form textarea::placeholder {
    font-size: var(--size-12);
}

.inner_review form input {
    width: 100%;
    display: block;
    padding: 3px;
    border: 1px solid var(--color-black);
    border-radius: 5px;
    box-sizing: border-box;
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-black);
    margin-bottom: 20px;
}
.inner_review form input::placeholder {
    font-size: var(--size-12);
}

.inner_review form span {
    font-size: var(--size-12);
    color: var(--color-black);
    font-weight: var(--medium);
    margin-bottom: 5px;
}

.inner_review form button {
    width: 100%;
    border: none;
    padding: 5px 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
    border-radius: 15px;
}



.reviews_list {
    border: 2px solid var(--color-grey);
    padding: 10px;
    margin-top: 40px;
    border-radius: 10px;
}
.review_item p {
    font-size: var(--size-15);
    margin-bottom: 10px;
}
.vote_buttons button {
    border: 2px solid var(--color-grey);
    background-color: transparent;
    font-family: var(--font);
    font-size: var(--size-14);
    margin-right: 10px;
    border-radius: 5px;
}


.button_item-rev {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.update_rev {
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-12);
    padding: 3px 10px;
}
.review_item {
    margin-bottom: 40px;
    border-bottom: 2px solid var(--color-grey);
    padding-bottom: 10px;
}
.review_item form {
    margin-top: 20px;
}
.replies_list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 20px;
}
.reply_item {
    width: 95%;
    padding: 10px;
    border: 2px solid var(--color-grey);
    border-radius: 15px;
    box-sizing: border-box;
}

.reply_item p {
    font-size: var(--size-15);
    margin-bottom: 0px;
}

.review_text {
    margin-top: 10px;
}
.data_review {
    font-size: var(--size-12);
    color: var(--color-black);
    margin-bottom: 20px;
}



.rev_title-all {
    width: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner_rev-all {
    width: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}
.inner_rev-all h2 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
}
.reviews-list {
    margin-top: 20px;
}

.review-item {
    width: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.inner-review-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}

.product-link_rev {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 10px;
}
.inner-review-item p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.inner-review-item span {
    font-size: var(--size-12);
    color: var(--color-black);
    margin-bottom: 5px;
}

.review-text {
    margin-bottom: 20px;
}
.review-descr {
    margin-bottom: 5px;
}