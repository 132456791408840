@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {background-color: #fff; font-family: 'Montserrat';}
::-webkit-scrollbar {width:0px;}
:root {

  /* Color */

  --color-black: #121113;
  --color-white: #fff;
  --shadow-color: #0d2750;
  --color-grey: #e6e6e678;
  --color-dark-grey: #a8a8a8;
  --background: #fdfbff87;
  --background-dark: #fdfbff;
  --violet: #888abb;
  --roze: #ff447c;

  /* Text size */

  --size-10: 10px;
  --size-11: 11px;
  --size-12: 12px;
  --size-13: 13px;
  --size-14: 14px;
  --size-15: 15px;
  --size-16: 16px;
  --size-18: 18px;
  --size-20: 20px;
  --size-22: 22px;
  --size-24: 24px;
  --size-25: 25px;
  --size-28: 28px;
  --size-30: 30px;
  --size-38: 38px;
  --size-40: 40px;
  --size-54: 54px;
  --size-82: 82px;

  /* Weight */

  --font: 'Montserrat';
  --regular: 500;
  --medium: 500;
  --semi-bold: 600;

}

.main-container {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.container {
  max-width: 1600px;
  padding: 0 10px;
  margin: 0 auto;
  height: 100%;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  z-index: 1000;
}
.notification.success {
  background-color: green;
}
.notification.warning {
  background-color: orange;
}
.notification.error {
  background-color: red;
}

.new-order-count-label,
.new-comment-count-label {
  width: 20px;
  padding: 2px 4px;
  background-color: var(--color-black);
  border-radius: 50%;
  margin-left: 10px;
  color: var(--color-white);
  font-weight: var(--semi-bold);
}

.user_agreement-container {
  width: 100%;
  height: 100%;
  padding: 3px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-sizing: border-box;
  margin-top: 80px;
}
.inner_agreement-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: var(--color-white);
  border-radius: 12px;
  box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-sizing: border-box;
}
.user_agreement-container h1 {
  font-size: var(--size-20);
  font-weight: var(--semi-bold);
  color: var(--color-black);
  margin-bottom: 40px;
}
.user_agreement-title {
  font-size: var(--size-15);
  font-weight: var(--semi-bold);
  color: var(--color-black);
}
.inner_agreement-container span {
  font-size: var(--size-14);
  color: var(--color-black);
  line-height: 1.2;
}



@media screen and (max-width: 1024px) {
  .categories_item {
    display: none;
  }
}
@media screen and (max-width: 997px) {
  .user_delivery-item {
    display: flex;
    flex-direction: column;
  }
  .delivery_item {
    width: 100%;
    margin-right: 0;
  }
  .delivery_courier {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
  .delivery_item select {
    width: 100%;
    font-family: var(--font);
    font-size: var(--size-14);
}
}
@media screen and (max-width: 775px) {
  .filter_title {
    display: none;
  }
  .filter_bar-btn {
    display: block;
    padding: 4px 8px;
    border: none;
    border-radius: 8px;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-12);
    color: var(--color-white);
  }
  .filter_container {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .delivery_courier {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 525px) {
  .catalog_link-m {
    display: block;
  } 
  .catalog_link {
    display: none;
  }
  .bottom_product,
  .descr_product-container {
    padding: 10px;
  }
  .order_wish {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
  .order_price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  .bottom_bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .pay_btn {
    width: 100%;
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    padding: 5px 15px;
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    margin-top: 20px;
  }
  .user_data-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .item_data {
    margin: 0;
  }
}

@media screen and (max-width: 475px) {
  .categories_grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 160px), 1fr));
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
    box-sizing: border-box;
  }
  .card_product-title {
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 140px;
    width: 100%;
    height: 28px;
    white-space: wrap;
    font-size: var(--size-14);
    margin: 10px 0;
    padding: 0 5px;
    text-align: center;
    box-sizing: border-box;
  }
}