.top_cabinet {
    max-width: 1600px;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    margin-top: 60px;
}
.inner_cabinet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}
.top_cabinet h2 {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
}
.top_cabinet button {
    border: none;
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    background-color: var(--color-black);
    padding: 3px 14px;
    border-radius: 10px;
}
.top_cabinet button img {
    width: 22px;
    height: 22px;
    margin-left: 5px;
}



.user_tabs {
    display: flex;
    max-width: 1600px;
    height: 100%;
}
.inner_user {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.tab_user-buttons {
    width: 220px;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.inner_user-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.tab_user-buttons button {
    width: 100%;
    border: none;
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: var(--size-14);
    font-family: var(--font);
    border-radius: 15px;
    padding: 7px 10px;
    margin-bottom: 10px;
}
.tab_user-content {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-left: 20px;
}
.inner_tab-user {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.cabinet_form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.form__container {
    display: flex;
    flex-direction: column;
}
.form_group {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}
.form_group-pass {
    display: flex;
    align-items: center;
}
.form_group-delivery {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.delivery_item-input {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.pay_deliver-item {
    display: flex;
}

.title_descr-cabinet {
    margin-bottom: 20px;
}
.title_descr-cabinet h3 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-bottom: 5px;
}
.title_descr-cabinet p {
    font-size: var(--size-14);
    color: var(--color-dark);
}


.form_group label p,
.form_group-pass label p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-bottom: 5px;
}
.delivery_item-input label p,
.pay_deliver-item label p {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-bottom: 5px;
}

.form_group label,
.form_group-pass label {
    width: 100%;
}
.form_group label p {
    font-size: var(--size-12);
}
.form_group label,
.delivery_item-input label,
.form_group-pass label {
    margin-right: 10px;
}
.form_group label input,
.delivery_item-input label input,
.form_group-pass label input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-13);
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.pay_deliver-item label select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-13);
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.pay_deliver-item label {
    margin-right: 10px;
}

.save__btn,
.save__btn-pass {
    width: 204px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: var(--color-dark);
    color: var(--color-white);
    font-size: var(--size-14);
    font-family: var(--font);
    margin-top: 40px;
}


/* Wish */


.wish_tab-info {
    width: 100%;
}
.grid_wish-container,
.product-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 200px), 1fr));
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 20px;
}
.wish_card {
    position: relative;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey);
    padding: 10px;
    border-radius: 6px; 
}
.viev_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: var(--color-white);
    border: 1px solid var(--color-bockground-grey);
    padding: 10px;
    border-radius: 6px; 
}
.wish_card a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.wish_image-card,
.view-img {
    width: 160px;
    height: auto;
    object-fit: cover;
}
.wish_card input {
    position: absolute;
    top: 10px;
    right: 10px;
}
.wish_title-text__card {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-top: 25px;
    text-align: center;
    width: 100%;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.delete_wish-btn {
    border: none;
    border-radius: 10px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-12);
    padding: 5px 10px;
    margin-top: 20px;
}
.view-title {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-top: 25px;
    text-align: start;
    width: 100%;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.save__btn {
    padding: 5px 10px;
    border: none;
    border-radius: 8px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
}



/* Order */


.order__content-container_tab {
    width: 100%;
}
.order__content-container_tab h1 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-bottom: 20px;
}
.select_btn-item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;
}
.button_check-item button,
.delete_btn {
    border: 1px solid var(--color-dark);
    background-color: var(--color-white);
    color: var(--color-dark);
    font-size: var(--size-14);
    font-weight: var(--medium);
    font-family: var(--font);
    border-radius: 5px;
    margin-left: 10px;
    padding: 2px 5px;
}
.filter-label p {
    color: var(--color-dark);
    font-size: var(--size-14);
    font-weight: var(--medium);
    margin-bottom: 5px;
}
.filter-label select {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 2px;
    font-size: var(--size-14);
    font-family: var(--font);
    border: 1px solid var(--color-black);
    font-weight: var(--medium);
    background-color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-13);
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.order_item-grid {
    display: flex;
    flex-direction: column;
}
.order_card-tab {
    position: relative;
    background-color: var(--color-white);
    border: 1px solid var(--color-grey);
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px; 
}
.order_card-tab span {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: var(--size-12);
    color: var(--color-dark);
    font-weight: var(--medium);
}
.check_card-input {
    position: absolute;
    top: 10px;
    right: 10px;
}


.order_card-tab__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.order-card_title {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-top: 40px;
    width: 100%;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.order-count_title,
.order-price_title {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.delete_btn-order {
    display: none;
}
.delete_btn-order img {
    width: 18px;
    height: 18px;
}


/* Login */

.login_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}
.login-item,
.register-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.login-item h2,
.register-item h2 {
    font-size: var(--size-20);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 30px;
}

.login-item form,
.register-item form {
    width: 340px;
}
.log_input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    border-radius: 5px;
    margin-bottom: 15px;
}
.log_input::placeholder,
.pass_input::placeholder {
    font-family: var(--font);
    font-size: var(--size-12);
}
.input__btn-pass {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
}
.pass_input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: var(--color-white);
}
.btn_pass {
    padding: 0;
    border: none;
    background-color: var(--color-white);
    margin-right: 10px;
}
.btn__enter {
    width: 100%;
    height: 30px;
    margin-top: 30px;
    border: none;
    background-color: var(--color-black);
    font-family: var(--font);
    color: var(--color-white);
    border-radius: 5px;
}

.button_title-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}
.button_title-item p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.log__enter {
    width: 100%;
    height: 30px;
    margin-top: 15px;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    font-family: var(--font);
    color: var(--color-black);
    font-weight: var(--medium);
    border-radius: 5px;
}




.reg_name,
.reg_phone,
.reg_mail {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    border: 1px solid var(--color-black);
    background-color: var(--color-white);
    border-radius: 5px;
    margin-bottom: 15px;
}
.input__btn-pass {
    margin-bottom: 15px;
}
.input__btn-pass input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    border: none;
    background-color: var(--color-white);
    border-radius: 5px;
}
.reg_name::placeholder,
.reg_phone::placeholder,
.reg_mail::placeholder,
.input__btn-pass input::placeholder {
    font-family: var(--font);
    font-size: var(--size-12);
}

.label__log {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid var(--color-grey);
    border-radius: 5px;
}
.label__log p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    margin-left: 20px;
}




.last-viewed-products {
    margin-top: 40px;
}
.last-viewed-products h2 {
    font-size: var(--size-22);
    font-weight: var(--semi-bold);
    margin-bottom: 30px;
}
.product_cards-view {
    display: flex;
    align-items: center;
    overflow-x: auto;
}
.product_card {
    position: relative;
    width: 190px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--color-grey);
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    box-sizing: border-box;
    text-align: center;
}
.product_card-img {
    width: 140px;
    height: auto;
    margin-bottom: 10px;
    object-fit: contain;
}
.product_card-title {
    text-align: start;
    margin-top: 20px;
    width: 100%;
    height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
}
.product_price-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.product_card-price {
    font-size: var(--size-18);
    color: var(--color-dark);
    font-weight: var(--medium);
}
.old_price-view {
    font-size: var(--size-12);
    text-decoration: line-through;
}

.save__btn-pass {
    background-color: var(--color-black);
    border-radius: 15px;
}





@media screen and (max-width: 793px) {
    .user_tabs {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .tab_user-buttons {
        width: 98.8%;
        margin-bottom: 20px;
    }
    .inner_user-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: auto;
        width: 100%;
        white-space: nowrap;
    }
    .inner_user-button button {
        width: 150px;
        margin-right: 20px;
        margin-bottom: 0;
        flex-shrink: 0;
    }
    .tab_user-content {
        margin-left: 0;
        width: 98.8%;
    }

    .form_group label,
    .delivery_item-input label {
        margin-right: 0;
    }
    .pay_deliver-item label,
    .form_group-pass label {
        margin-right: 0;
    }
    .form_group {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }
    .form_group-pass {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .save__btn-pass {
        margin-top: 10px;
    }
    .form_group label,
    .form_group-pass label {
        margin-bottom: 15px;
    }
    .delivery_item-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
    }
    .delivery_item-input label,
    .pay_deliver-item label {
        width: 100%;
        margin-bottom: 15px;
    }
    .pay_deliver-item {
        display: flex;
        flex-direction: column;
    }
    .save__btn {
        width: 100%;
        height: 30px;
        border: none;
        border-radius: 5px;
        background-color: var(--color-black);
        color: var(--color-white);
        font-size: var(--size-14);
        font-family: var(--font);
        margin-top: 40px;
    }
}
@media screen and (max-width: 610px) {
    .tab_user-content {
        margin-left: 0;
        width: 98.8%;
    }
}
@media screen and (max-width: 510px) {
    .tab_user-content {
        margin-left: 0;
        width: 98.8%;
    }
}
@media screen and (max-width: 495px) {
    .order_item-grid {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .order-card_title {
        font-size: var(--size-14);
        font-weight: var(--medium);
        color: var(--color-dark);
        margin-top: 25px;
    }
}
@media screen and (max-width: 470px) {
    .grid_wish-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 150px), 1fr));
        gap: 10px;
        margin-top: 30px;
        padding: 15px;
    }
    .order_item-grid {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .wish_image-card {
        width: 100px;
        height: auto;
        object-fit: cover;
        border-radius: 50%;
    }
    .wish_title-text__card {
        font-size: var(--size-14);
        font-weight: var(--medium);
        color: var(--color-dark);
        margin-top: 25px;
        width: 150px;
        text-align: center;
    }
    .button_check-item {
        display: flex;
    }
    .delete_btn-order {
        display: flex;
        border: 1px solid var(--color-dark);
        background-color: var(--color-white);
        color: var(--color-dark);
        font-size: var(--size-14);
        font-weight: var(--medium);
        font-family: var(--font);
        border-radius: 5px;
        margin-left: 10px;
        padding: 2px 5px;
    }
    .delete_btn {
        display: none;
    }
}
@media screen and (max-width: 410px) {
    .tab_user-content {
        margin-left: 0;
        width: 98.8%;
    }
}