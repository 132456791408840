.panel_container {
    max-width: 1600px;
    height: 100%;
}
.inner_container {
    max-width: 100%;
}

.login-container_panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    padding: 40px 0;
}
.login-panel {
    width: 400px;
    height: 200px;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner_login-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}
.inner_login-panel form {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}
.inner_login-panel form input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
}
.inner_login-panel form button {
    padding: 4px 10px;
    border: none;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    margin-top: 30px;
    border-radius: 8px;
}

.left_panel {
    width: 100%;
    height: 100%;
}



.tab_container {
    display: flex;
    column-gap: 20px;
}
.tab_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 260px;
    height: 540px;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: sticky;
    top: 20px;
}
.inner_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 240px;
    height: 540px;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}
.btn_item-title {
    font-size: var(--size-18);
    font-weight: var(--medium);
    margin: 20px 0 40px 0;
}
.inner_button button {
    font-family: var(--font);
    font-size: var(--size-14);
    font-weight: var(--medium);
}

.tab_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    padding: 6px 10px;
    border: none;
    background-color: var(--background);
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.1s ease;
    margin-bottom: 5px;
}
.tab_button.active {
    background-color: var(--color-black);
    color: white;
}
.tab_icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    transition: transform 0.2s ease;
}
.tab_label {
    transition: transform 0.2s ease;
}
.tab_button:hover .tab_icon {
    transform: scale(1.3);
}
.tab_button:hover .tab_label {
    transform: translateX(5px);
}
.exit_btn {
    width: 200px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-white);
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    margin-top: 40px;
}

.tab_content {
    width: 100%;
    height: 100%;
}



.button_tab-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
}
.inner_tab_buttons {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
}

.inner_tab_button {
    padding: 5px 10px;
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
    margin-right: 10px;
}
.inner_tab_button:hover {
    padding: 2px 8px;
    border: 2px solid var(--color-black);
    border-radius: 15px;
    background-color: var(--color-white);
    color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-14);
    font-weight: var(--medium);
    margin-right: 10px;
}

.create_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 20px;
    box-sizing: border-box;
}
.slider-prod-manager {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 20px;
}
.inner_create {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
}



.inner_tab_content,
.create {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}
.create_form {
    display: flex;
    flex-direction: column;
}
.create_form span {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}
.create_title {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin: 20px 0;
}
.create_form input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
    height: 30px;
}
.create_form input::placeholder {
    font-family: var(--font);
    font-size: var(--size-14);
}
.create_form textarea {
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
}
.file-upload {
    display: flex;
    align-items: center;
    width: 200px;
    padding: 5px;
    background-color: var(--color-black);
    border-radius: 8px;
    cursor: pointer;
}
.upload-preview {
    width: 20px;
    height: 20px;
}
.file-upload p {
    color: var(--color-white);
    font-size: var(--size-12);
    margin-left: 10px;
}
.custom-file-upload {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.uploaded-images {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    border: 1px solid var(--color-black);
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.uploaded-images img {
    width: 100px;
}
.icon_btn {
    border: none;
    padding: 7px 10px;
    background-color: var(--color-black);
    border-radius: 8px;
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-12);
    margin-left: 20px;
}
.icon-list {
    padding: 10px;
    border: 1px solid var(--color-black);
    border-radius: 8px;
    margin-bottom: 20px;
}
.create_btn,
.delete-btn {
    border: none;
    padding: 7px 10px;
    background-color: var(--color-black);
    border-radius: 8px;
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-12);
    margin-bottom: 40px;
}
.create select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid var(--color-black);
    border-radius: 8px;
    font-family: var(--font);
    margin-bottom: 20px;
}
.select_title {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}


.color_container {
    border-radius: 8px;
    border: 1px solid var(--color-black);
    padding: 10px;
}
.color-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24px, 1fr));
    gap: 10px;
}
.color-square {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 8px;
    border: 2px solid transparent;
    transition: border 0.3s;
    margin-right: 3px;
}
.color-square.selected {
    border: 2px solid black;
}
.tooltip {
    position: absolute;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    border-radius: 4px;
    pointer-events: none;
    z-index: 1000;
}
.color_btn {
    border: none;
    border-radius: 8px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-12);
    padding: 5px 10px;
    margin-bottom: 20px;
}
.color-title {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin: 20px 0;
}

.characteristics-section {
    display: flex;
    flex-direction: column;
}
.input_container {
    display: flex;
    align-items: center;
}
.characteristic-inputs input {
    margin-right: 20px;
}
.characteristic-inputs input::placeholder {
    font-family: var(--font);
    font-size: var(--size-12);
}
.char_btn-plus {
    padding: 6px 10px;
    border: none;
    border-radius: 8px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-12);
    width: 200px;
    margin-bottom: 40px;
}
.remove-char-btn {
    border: none;
    padding: 0;
    background-color: transparent;
    margin-bottom: 10px;
}
.create_page-btn {
    padding: 5px;
    border: none;
    border-radius: 8px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
}


.soc_item-footer {
    width: 100%;
}
.soc_item-footer div input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-top: 5px;
    margin-bottom: 15px;
    font-family: var(--font);
    font-size: var(--size-14);
}
.soc_title {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 10px;
}
.soc_text {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin: 20px 0;
}
.soc_item-footer button {
    padding: 4px 10px;
    border: none;
    border-radius: 8px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
}





.user_tab-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.inner_user-tab {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
}


.order_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
}


.order-count {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    margin-right: 20px;
    position: sticky;;
    top: 300px;
}
.inner_order-count {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}
.inner_order-count h4,
.inner_order-count p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.custom-datepicker {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
    margin-right: 20px;
    position: sticky;
    top: 20px;
}
.inner-data {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.date-picker-container {
    margin-left: 20px;
}
.datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.datepicker-header button {
    background: none;
    border: none;
    cursor: pointer;
}
.datepicker-header span {
    font-weight: var(--medium);
    font-size: 16px;
}
.datepicker-days-of-week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    margin-bottom: 5px;
}
.datepicker-day-name {
    font-weight: bold;
}
.datepicker-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}
.datepicker-day {
    text-align: center;
    font-family: var(--font);
    font-size: var(--size-14);
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.datepicker-day.selected {
    background-color: var(--color-black);
    border-radius: 4px;
    color: white;
}


.order-li {
    width: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 20px;
}
.inner_order_li {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.number_data,
.order_price-all {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 0;
}
.number_data p,
.order_price-all p {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
}
.user_order-data {
    margin-top: 20px;
}
.user_order-data h4 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 10px;
}
.user_data-col {
    display: flex;
    align-items: center;
}
.user_data-col p {
    font-size: var(--size-15);
    color: var(--color-black);
    margin-right: 20px;
}


.delivery_user-order,
.delivery_user-pay,
.order_descr-user {
    margin-top: 20px;
}
.delivery_user-order h4,
.delivery_user-pay h4,
.order_descr-user h4 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 10px;
}
.delivery_user-order p,
.delivery_user-pay p,
.order_descr-user p {
    font-size: var(--size-15);
    color: var(--color-black);
    line-height: 1.2;
}


.visit-chart {
    display: flex;
    flex-direction: column;
}
.chart-body {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.online-user-item {
    width: 300px;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-right: 20px;
}
.inner_online h3 {
    display: flex;
    align-items: center;
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.inner_user,
.inner_chart,
.inner_online {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}
.inner_user h3 {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.user_item-row {
    display: flex;
    margin-bottom: 20px;
}
.online-dot {
    display: block;
    width: 10px;
    height: 10px;
    background-color: darkgreen;
    border-radius: 100%;
    margin-right: 10px;
}

.user_containet-tb {
    border: 1px solid var(--color-dark-grey);
}
.user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-dark-grey);
}
.user-item:last-child {
    border-bottom: 0;
}
.item_tb {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-start;
    border-right: 1px solid var(--color-dark-grey);
}
.item_tb:last-child {
    border-right: 0;
}




.visit-list {
    margin-left: 20px;
}
.list_header-col {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.header-visit,
.visit-list {
    width: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.header-visit {
    margin-right: 20px;
}
.average-visits {
    width: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner_average-visits {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}
.inner_header-visit,
.inner_visit-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}
.inner_header-visit h3,
.inner_average-visits h3 {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.visit-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.visit-list-item p {
    font-size: var(--size-14);
    color: var(--color-black);
}


.time_count-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}
.time_count-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
}





.average-bar-chart-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-top: 20px;
}
.inner-bar_chart {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}
.average-bar-chart {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
}
.bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.average-visit-label {
    margin-bottom: 5px;
    font-size: 12px;
    color: #333;
}
.bar {
    background-color: #000;
    width: 30px;
    transition: height 0.3s ease;
}


.date-container {
    width: 100%;
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    gap: 20px;
}
.daily-stats {
    width: 100%;
    height: 40px;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner_daily-stats {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}

.visits-datepicker {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner_visits-datepicker {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}



.map_container {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-top: 20px;
}
.inner_map {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-family: var(--font);
    font-size: var(--size-14);
    box-sizing: border-box;
}

.hidden_container {
    height: 100%;
    overflow: hidden;
}

.city_container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.city_container h2 {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}
.city_container li {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}

.loading_data {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-left: 20px;
}




.edit_form-product,
.delete_product,
.seo_manager {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 20px;
    box-sizing: border-box;
}
.seo_manager {
    margin: 0;
}
.inner_edit-form,
.inner_delete,
.inner_manager {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}


.product-search {
    position: relative;
    width: 100%;
    margin: 0 auto;
}
.product-search input {
    display: block;
    width: 100%;
    padding: 3px;
    box-sizing: border-box;
    border: 1px solid var(--color-black);
    border-radius: 5px;
    font-family: var(--font);
    font-size: var(--size-14);
}
.product-list {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid var(--color-black);
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    height: 400px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 1000;
    margin: 0;
    padding: 5px;
    list-style: none;
}
  
.product-list li {
    padding: 3px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: var(--font);
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.product-list li:hover {
    background-color: #f5f5f5;
}
.product-list li:active {
    background-color: #e9ecef;
}

.edit-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    box-sizing: border-box;
}
.edit-form span {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}
.edit-form input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
}
.edit-form textarea {
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
}
.upload_title {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-left: 20px;
}
.inputs_container {
    display: flex;
    align-items: center;
}
.inputs_container input {
    margin-right: 20px;
    font-family: var(--font);
    font-size: var(--size-14);
    font-weight: var(--medium);
}
.characteristics-section {
    margin-top: 20px;
}
.characteristics-section h3 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin: 20px 0;
}
.inputs_container input::placeholder {
    font-size: var(--size-12);
    font-family: var(--font);
}
.update {
    width: 100%;
    padding: 5px;
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-size: var(--size-14);
    font-family: var(--font);
}

.inner_delete h2 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
}
.delete_container {
    margin-top: 20px;
}
.delete_container h3 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}
.delete_page-btn {
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
    padding: 4px 10px;
}



.inner_manager p {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}
.product-selector {
    position: relative;
    width: 100%;
    margin: 0 auto;
}
.product-selector input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
}
.product-selector input::placeholder {
    font-size: var(--size-12);
    font-family: var(--font);
}

.seo-list {
    margin-top: 20px;
}
.seo-list h3 {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}

.seo-create label,
.seo-edit label {
    display: flex;
    flex-direction: column;
    font-family: var(--font);
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
}
.seo-create h4 {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}
.seo-create label input,
.seo-create label textarea,
.seo-edit label input,
.seo-edit label textarea {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: var(--size-14);
    font-family: var(--font);
}

.seo-create label textarea,
.seo-edit label textarea {
    height: 300px;
    max-height: 300px;
}

.save_seo-btn,
.update_seo-btn {
    padding: 5px 15px;
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
    margin-top: 20px;
    margin-right: 10px;
}
.update_seo-btn img {
    margin-left: 10px;
}
.seo-view p {
    font-size: var(--size-14);
    line-height: 1.1;
}


.promo-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.promo-section select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
}



.create_filter-item {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin: 20px 0 30px 0;
}
.create_inner-filter {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}
.create_inner-filter h3,
.create_filter h4,
.filter_add h4 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin: 10px 0;
}

.filter_item-select,
.create_filter {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.filter_item-select label,
.create_filter label {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-bottom: 5px;
}
.filter_item-select select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
    font-family: var(--font);
    font-size: var(--size-14);
}
.create_filter input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
    font-family: var(--font);
    font-size: var(--size-14);
}

.filter_input-item input {
    width: 50%;
}
.filter_item-btn {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.filter_item-btn button {
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
    padding: 4px 15px;
    margin-right: 20px;
}


.filter_add {
    margin-top: 30px;
}
.filter_add-btn {
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    font-family: var(--font);
    font-size: var(--size-14);
    padding: 4px 10px;
    width: 180px;
    margin-top: 30px;
}
.filter_add p {
    margin-bottom: 10px;
}
.filter_add ul li {
    margin-bottom: 3px;
}

.existing_filter-item {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: 30px;
}
.existing_filter-item li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.existing_filter-item p {
    font-size: var(--size-15);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 10px;
}
.existing_filter-item ul li {
    width: 50%;
    font-size: var(--size-14);
    color: var(--color-black);
    margin-bottom: 5px;
}
.delete_option {
    border: none;
    background-color: transparent;
    margin: 0;
}

.delete_filter-item {
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    padding: 4px 10px;
    margin: 10px 0 20px 0;
}
.existing_filter-item h4 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin: 10px 0 20px 0;
}



.analitic_container {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner_analitic-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.inner_analitic-container h3 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 30px;
}
.analitic_list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--color-grey);
    margin-bottom: 10px;
}
.title_btn-history h4 {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.title_btn-history {
    width: 100%;
}
.title_btn-history button {
    padding: 4px 10px;
    background-color: var(--color-black);
    border: none;
    border-radius: 10px;
    font-family: var(--font);
    font-size: var(--size-12);
    color: var(--color-white);
    margin-top: 10px;
}

.visit_history-analitic {
    width: 100%;
    border: 2px solid var(--color-grey);
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    box-sizing: border-box;
}
.visit_history-analitic h5 {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 10px;
}

.visit_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.visit_header, .visit_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid var(--color-grey);
}
.visit_header {
    font-size: var(--size-14);
    font-weight: var(--medium);
}
.visit_column {
    flex: 1;
    text-align: left;
    padding: 0 10px;
}
.visit_row p {
    font-size: var(--size-12);
}

.item_data-time__analitic {
    margin: 20px 0;
}
.item_data-time__analitic p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}


.search_container {
    position: relative;
    width: 100%;
}
.search_input-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-black);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 3px 0;
}
.search_input-btn input {
    display: block;
    width: 100%;
    padding: 3px 5px;
    border: none;
    border-radius: 10px;
    transition: border-color 0.2s ease-in-out;
}
.search_input-btn input::placeholder {
    font-family: var(--font);
    font-size: var(--size-12);
}
.clear_button {
    border: none;
    background-color: transparent;
}
.clear_button img {
    width: 15px;
}
.dropdown_results {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
    z-index: 1000;
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
}
.dropdown_item {
    padding: 10px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}
.dropdown_item:hover {
    background-color: #f1f1f1;
}
.dropdown_item h4 {
    margin: 0;
    font-size: 16px;
    color: #333;
    word-wrap: break-word;
}



.date_filter_container {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.inner_data-calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.inner_data-calendar input {
    border: 1px solid var(--color-black);
    padding: 3px 5px;
    border-radius: 8px;
    width: 100px;
}
.inner_data-calendar label {
    font-size: var(--size-12);
    margin-bottom: 5px;
}


.credentials_title {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.inner_credentials-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}
.inner_credentials-title h2 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
}

.update-credentials_form {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner-update-credentials {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}
.inner-update-credentials form {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.form_item-col {
    display: flex;
    flex-direction: column;
    width: 30%;
}
.label_title-input {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}
.pass_btn-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.input_col {
    display: block;
    width: 100%;
    height: 30px;
    padding: 2px 4px;
    border: 1px solid var(--color-black);
    border-radius: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
}
.pass_btn-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    padding: 2px 4px;
    border: 1px solid var(--color-black);
    border-radius: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
}
.pass_btn-form input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.pass_btn-form button {
    border: none;
    background-color: transparent;
}
.pass_btn-credentials {
    width: 150px;
    padding: 4px 12px;
    border: none;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    border-radius: 8px;
}



.create_categori-container__row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}
.row_input-input {
    width: 100%;
    margin-right: 20px;
}
.custom_file__upload {
    margin-bottom: 10px;
}
.row_input-input p,
.additional-block div {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}
.additional-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.additional-block_input__row {
    width: 100%;
    padding-bottom: 6px;
    margin-right: 20px;
}
.remove-block-btn {
    padding: 0;
    border: none;
    background-color: transparent;
    margin-left: 20px;
}
.add-block-btn {
    padding: 5px 10px;
    border: none;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    border-radius: 8px;
    margin-bottom: 40px;
    width: 200px;
}

.edit-section {
    width: 100%;
    box-sizing: border-box;
}
.edit-section h2 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin: 20px 0;
}
.card-edit-block {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.card-edit-block input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
    height: 30px;
}
.card-edit-block input::placeholder {
    font-family: var(--font);
    font-size: var(--size-14);
}
.card-edit-block button {
    padding: 6px 10px;
    border: none;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    border-radius: 8px;
    margin-bottom: 10px;
    margin-left: 20px;
    width: 100px;
}
.card-edit-block_title {
    width: 100%;
    margin-right: 20px;
}
.card-edit-block_title p {
    font-size: var(--size-12);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 5px;
}
.delete-section h2 {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-black);
    margin: 20px 0;
}
.card-delete-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    margin-bottom: 10px;
}
.card-delete-block button {
    padding: 0;
    border: none;
    background-color: transparent;
}
.card-delete-block span {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}