.product_container {
  max-width: 1600px;
  height: 100%;
  padding: 3px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 80px;
}
.inner_product {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  border-radius: 12px;
  box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.top_product {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.slider-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 450px;
  margin: auto;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}
.slide-image {
  width: 100%;
  max-width: 450px;
  object-fit: cover;
}
.arrow {
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 10px;
  border-radius: 50%;
  background-color: transparent;
}
.arrow.left {
  left: 10px;
}
.arrow.right {
  right: 10px;
}
.arrow img {
  width: 30px;
  height: 30px;
}


.stock_color-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.stock_code {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
}
.color_title-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.stock,
.code,
.color_title {
  font-size: var(--size-12);
  color: var(--color-black);
  font-weight: var(--medium);
}
.color_title {
  margin-right: 20px;
}
.color_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding: 1px;
  border: 1px solid var(--color-black);
  border-radius: 50%;
  margin-right: 5px;
}
.color {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}

.bottom_product {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding: 20px;
}
.prodct_title,
.product_price,
.char_descr-title,
.descr_title {
  font-size: var(--size-20);
  font-weight: var(--medium);
  color: var(--color-black);
}
.order_price {
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
}
.order_wish {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.add-order_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 15px;
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: var(--font);
  font-size: var(--size-14);
  padding: 6px 15px;
  margin-right: 20px;
}
.add-order_btn img {
  width: 17px;
  height: 17px;
  margin-left: 10px;
}
.add-order_wish {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  background-color: var(--color-black);
  border-radius: 50%;
}
.add-order_wish.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.descr_char-container {
  margin-top: 30px;
  padding: 20px 10px;
}


.descr_text-wrapper {
  max-height: 100px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s ease;
}
.descr_text-wrapper.expanded {
  max-height: none;
}
.descr_text {
  text-align: justify;
  font-size: var(--size-14);
  line-height: 1.2;
  margin: 20px 0;
}
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%);
  pointer-events: none;
}
.toggle-button {
  background-color: var(--color-black);
  color: white;
  font-family: var(--font);
  font-size: var(--size-14);
  border: none;
  padding: 4px 10px;
  margin-top: 20px;
  margin-bottom: 60px;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.2s;
  
}


.char_list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
}
.char_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.char_name {
  flex: 1;
  text-align: left;
  font-size: var(--size-14);
}
.char_value {
  flex: 1;
  font-size: var(--size-14);
}


.promo {
  margin-top: 5px;
  padding: 3px 5px;
  border-radius: 5px;
  color: var(--color-white);
  font-size: var(--size-14);
  text-align: center;
}

.old_price {
  text-decoration: line-through;
  font-size: var(--size-14);
  margin-bottom: 10px;
}