.slider_container-hero {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
}
.arrow_btn-hero {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
}
.arrow_btn-hero.visible {
    opacity: 1;
}
.arrow_btn-hero.next {
    right: 10px;
}
.arrow_btn-hero.prev {
    left: 10px;
}
.slick-slide {
    display: flex;
    justify-content: center;
    padding: 0 5px;
}
.slider_container-hero img {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}
.slider_container-hero a {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}



.create_slider-hero__form {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin: 20px 0 30px 0;
}
.inner_slider-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.inner_slider-form form {
    display: flex;
    flex-direction: column;
}
.custom-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    padding: 3px 10px;
    background-color: var(--color-black);
    border-radius: 8px;
    cursor: pointer;
}
.custom-upload span {
    font-size: var(--size-12);
    color: var(--color-white);
}
.edit_card {
    margin-top: 20px;
}
.edit_card input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 20px;
    font-family: var(--font);
    font-size: var(--size-14);
}
.edit_card select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
    font-family: var(--font);
    font-size: var(--size-14);
}
.create_slide-btn {
    width: 150px;
    padding: 5px 12px;
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-12);
    color: var(--color-white);
    font-weight: var(--medium);
    margin-top: 10px;
    cursor: pointer;
}

.slides_list {
    display: flex;
    align-items: center;
}
.slide_item {
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
}

.delete-slide_btn {
    border: none;
    background-color: transparent;
}

.tab-select-wrapper {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.inner_select-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.inner_select-wrapper label {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 10px;
}
.inner_select-wrapper select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 10px;
    font-family: var(--font);
    font-size: var(--size-15);
    font-weight: var(--medium);
}

.create_line,
.edit__line,
.delete_card-line {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-top: 20px;
}
.line_tab {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner_create-line,
.inner_line-tab,
.inner_edit-line,
.inner_delete-line {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}

.card_line-btn,
.card_line-btn-edit {
    padding: 4px 15px;
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    margin-top: 10px;
}
.card_line-btn-edit {
    margin-top: 20px;
}

.card_line-select select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 20px;
    font-family: var(--font);
    font-size: var(--size-15);
    font-weight: var(--medium);
}
.card_line-select input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid var(--color-black);
    margin-bottom: 20px;
    font-family: var(--font);
    font-size: var(--size-15);
    font-weight: var(--medium);
}