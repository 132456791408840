.nav_container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding-top: 10px;
    background-color: var(--color-white);
}
.nav_item {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1600px;
    padding: 0 10px;
    margin: 0 auto;
    height: 60px;
}
.inner_nav {
    width: 1600px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--color-white);
}
.border_nav {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.logo_catalog-link {
    display: flex;
    align-items: center;
}
.logo {
    font-size: var(--size-18);
    font-weight: var(--semi-bold);
    color: var(--color-dark);
    margin-left: 20px;
}
.catalog_link {
    padding: 5px 10px;
    margin-right: 20px;
    color: var(--color-white);
    background-color: var(--color-black);
    border-radius: 10px;
}
.catalog_link-m {
    display: none;
}
.catalog_link-m img {
    width: 22px;
    height: 22px;
}
.catalog_link img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}
.nav_btn-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
}

.search_btn,
.cabinet_btn,
.bag_btn {
    padding: 0;
    border: none;
    background-color: var(--background);
    margin-left: 30px;
    position: relative;
}
.bag-indicator {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
}
.search_btn img,
.cabinet_btn img {
    width: 23px;
    height: 23px;
}



.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.sidebar-content {
    width: 100%;
    max-width: 800px;
    min-height: 500px;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--color-white);
    box-sizing: border-box;
    margin: 0 10px;
}
.inner_side-bar {
    width: 100%;
    max-width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}
.top_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}
.bar_title {
    font-size: var(--size-14);
    font-weight: var(--medium);
}
.order_item-all {
    width: 100%;
}
.close-btn {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
}

.order_item-all {
    min-height: 400px;
    margin-bottom: 10px;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--color-white);
    box-sizing: border-box;
}
.inner_order-list {
    min-height: 400px;
    max-height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    box-sizing: border-box;
}


.order_list-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-grey);
    margin-bottom: 20px;
}
.image_count-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.order_image-bag {
    width: 100px;
    margin-right: 10px;
}
.order_title-bag {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    min-width: 150px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.order_price-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.order_price-bag {
    font-size: var(--size-18);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-left: 10px;
}
.count_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90px;
    padding: 5px;
    border: 1px solid var(--color-black);
    border-radius: 10px;
    margin-top: 10px;
}
.minus_btn,
.plus_btn {
    padding: 0;
    border: none;
    background-color: transparent;
}
.minus_btn img,
.plus_btn img {
    width: 20px;
    height: 20px;
}

.bottom_bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.all_price,
.all_count {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-bottom: 5px;
}
.pay_btn {
    font-family: var(--font);
    font-size: var(--size-14);
    color: var(--color-white);
    padding: 5px 15px;
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
}
.delete_order-btn {
    padding: 0;
    border: none;
    background-color: transparent;
    margin-left: 20px;
}


.cart-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid var(--color-grey);
    border-radius: 10px;
}
.cart-summary p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
    line-height: 1.2;
}
.open_bag {
    padding: 0;
    margin-right: 20px;
    border: none;
    background-color: transparent;
    font-family: var(--font);
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-dark);
}
.open_order {
    border: none;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-white);
    padding: 4px 8px;
    border-radius: 5px;
}


.search_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.serch_input-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    box-sizing: border-box;
    margin: 20px 10px;
} 
.search-input {
    display: block;
    box-sizing: border-box;
    width: 80%;
    height: 30px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
}
.search-input::placeholder {
    font-family: var(--font);
    font-size: var(--size-14);
}
.search_submit {
    height: 30px;
    font-family: var(--font);
    font-size: var(--size-14);
    border: none;
    border-radius: 5px;
    background-color: #ff6b00;
    color: var(--color-white);
    cursor: pointer;
    margin-left: 5px;
}
.search_submit:hover {
    background-color: #e65a00;
}

.search_results_container {
    width: 100%;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
    margin-top: 100px;
}
.inner-search_results {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    box-sizing: border-box;
}
.inner-search_results h2 {
    font-size: var(--size-22);
    font-weight: var(--medium);
    margin: 20px 0 20px 10px;
}
.results_count {
    font-size: var(--size-16);
    font-weight: var(--medium);
    margin: 10px 0 10px 10px;
}


















@media screen and (max-width: 575px) {
    .open_bag {
        display: none;
    }
}