.categories_item {
    width: 350px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-right: 20px;
}
.menu_categories {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.menu_categories ul {
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
}
.menu_categories ul li {
    margin-bottom: 5px;
    padding: 2px 3px;
    transition: transform 0.2s ease;
}
.menu_categories a {
    display: flex;
    align-items: center;
    font-size: var(--size-14);
    font-weight: var(--medium);
    transition: transform 0.2s ease;
}
.menu_categories ul li:hover {
    transform: translateX(5px);
}
.menu_categories a:last-child {
    margin-bottom: 0;
}
.catalog_link-li img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
    transition: transform 0.2s ease;
}
.menu_categories ul li:hover .catalog_link-li img {
    transform: scale(1.2);
}



.catalog_container,
.podcategories_container {
    max-width: 1600px;
    height: 100%;
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 80px;
}
.inner_catalog {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.catalog_title,
.categories_title,
.podcategories_title {
    font-size: var(--size-24);
    font-weight: var(--medium);
    margin: 20px 0 0 20px;
}
.catalog_grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 200px), 1fr));
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
}
.categories_grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 200px), 1fr));
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
    margin-top: 40px;
    box-sizing: border-box;
}
.categories_grid-pod {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 200px), 1fr));
    column-gap: 10px;
    row-gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}
.catalog_card,
.categories_card,
.pod-categories_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 10px;
    border-radius: 10px;
    border: 2px solid var(--color-grey);
}

.categories_card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 10px;
    border-radius: 10px;
    border: 2px solid var(--color-grey);
}

.catalog_card p,
.pod-categories_card p {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    text-align: center;
    margin-top: 10px;
}
.catalog_card img,
.pod-categories_card img,
.categories_card img {
    width: 150px;
    height: auto;
}
.categories_card img {
    margin-top: 20px;
}
.card_product-title {
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 200px;
    width: 100%;
    height: 28px;
    white-space: wrap;
    font-size: var(--size-14);
    margin: 10px 0;
    padding: 0 5px;
    text-align: justify;
    box-sizing: border-box;
}
.card_product-price {
    display: flex;
    flex-direction: column;
    font-size: var(--size-16);
    font-weight: var(--semi-bold);
}

.stock_card {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: var(--size-12);
    color: var(--color-black);
    font-weight: var(--medium);
}
.promo_label {
    padding: 3px 5px;
    color: var(--color-white);
    border-radius: 4px;
    text-align: center;
    margin-top: 5px;
}
.color_item-card {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    padding: 1px;
    border: 1px solid var(--color-black);
    border-radius: 50%;
}


.filter_grid {
    display: flex;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
}
.filter_container,
.filter_container-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 250px;
    padding: 10px;
    border: 2px solid var(--color-grey);
    margin-top: 10px;
    border-radius: 10px;
}
.filter_title {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
}
.filter_title-bar {
    font-size: var(--size-14);
    font-weight: var(--medium);
    color: var(--color-black);
    margin-bottom: 20px;
}

.filter_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px 0;
    cursor: pointer;
}
.filter_item p {
    font-size: var(--size-14);
    margin-bottom: 15px;
    font-weight: var(--medium);
}
.filter_checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: var(--size-14);
    font-weight: var(--medium);
}
.filter_checkbox input {
    margin-right: 10px;
}
.filter_name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nothing_found {
    padding: 10px;
    border: 2px solid var(--color-grey);
    border-radius: 10px;
}

.product-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add_btn-card {
    border: none;
    border-radius: 15px;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-12);
    color: var(--color-white);
    padding: 4px;
}
.price_add-item,
.delivery_icon-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}
.price_add-item img {
    width: 18px;
    margin: 0;
}
.delivery_icon-title p {
    font-size: var(--size-10);
    color: var(--color-black);
    font-weight: var(--medium);
}
.delivery_icon-title img {
    width: 24px;
    margin: 0;
}


.reviews_block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
}
.reviews_block img {
    width: 18px;
    margin: 0;
}
.reviews_block p {
    font-size: var(--size-12);
    margin-left: 10px;
}

.sort_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
    margin-top: 20px;
}

#sort-select {
    padding: 3px;
    border: 1px solid var(--color-dark-grey);
    font-family: var(--font);
    font-size: var(--size-14);
    border-radius: 5px;
}

.filter_bar {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    background-color: var(--color-white);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease;
    z-index: 1000;
}
.filter_bar.open {
    left: 0;
}
.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-family: var(--font);
    font-size: var(--size-14);
    font-weight: var(--medium);
}
.close-sidebar-btn {
    background: none;
    border: none;
    cursor: pointer;
}

.filter_bar-content {
    height: 100%;
    padding: 10px;
    overflow: hidden;
}
  
.filter_bar-btn {
    display: none;
    padding: 4px 8px;
    border: none;
    border-radius: 8px;
    background-color: var(--color-black);
    font-family: var(--font);
    font-size: var(--size-12);
    color: var(--color-white);
}
.filter_bar-btn img {
    width: 18px;
    margin-right: 5px;
}