.slider-card-manager {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 30px;
}
.inner_card-manager {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 12px;
  box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.create_slider-container form {
  width: 100%;
  margin-top: 20px;
}
.slide_title {
  font-size: var(--size-16);
  font-weight: var(--medium);
  color: var(--color-black);
  margin: 20px 0;
}
.block_title {
  font-size: var(--size-12);
  font-weight: var(--medium);
  color: var(--color-dark);
  margin-bottom: 5px;
}

.delete_slide {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.delete_slide select {
  position: relative;
  width: 100%;
  cursor: pointer;
  font-family: var(--font);
  font-size: var(--size-14);
  font-weight: var(--medium);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}
.delete_slide-btn {
  padding: 6px 10px;
  width: 150px;
  border: none;
  border-radius: 15px;
  color: var(--color-white);
  background-color: var(--color-black);
  font-family: var(--font);
  font-size: var(--size-12);
  margin-top: 20px;
}




.slider__container {
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  width: 100%;
}
.slider_header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
.slider_header h2 {
  font-size: var(--size-18);
  font-weight: var(--medium);
  color: var(--color-dark);
  margin-bottom: 30px;
}
.arrows_slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}
.prev_arrow,
.next_arrow {
  padding: 5px 15px;
  border: 1px solid var(--color-bockground-grey);
  border-radius: 5px;
  cursor: pointer;
}
.slider-card {
  position: relative;
  max-width: 190px;
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-grey);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
}
.slider-card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  margin-top: 40px;
  object-fit: contain;
}
.card_slider-title {
  text-align: start;
  margin-top: 20px;
  width: 100%;
  height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--size-14);
  font-weight: var(--medium);
  color: var(--color-dark);
}
.card_slider-price {
  text-align: start;
  margin-top: 20px;
  width: 100%;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--size-16);
  font-weight: var(--medium);
  color: var(--color-dark);
}
.card_slider-old-price {
  font-size: var(--size-12);
  margin-bottom: 3px;
  text-decoration: line-through;
}
.slick-track {
  display: flex !important;
}
.slick-slide {
  padding: 0 5px;
  box-sizing: border-box;
}
.slick-list {
  margin: 0 -5px;
}

.card_slider-promo {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  color: var(--color-white);
  font-size: var(--size-14);
  text-align: center;
}
.color_item-card {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding: 1px;
  border: 1px solid var(--color-black);
  border-radius: 50%;
  margin-right: 5px;
}
.card_slider-color {
  width: 13px;
  height: 13px;
  border-radius: 50%;
}



.custom-select-container {
  position: relative;
  width: 100%;
  cursor: pointer;
  font-family: var(--font);
  font-size: var(--size-14);
  font-weight: var(--medium);
}
.selected-option {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10;
  margin-top: 5px;
  padding: 5px 0;
}
.search_input {
  display: block;
  padding: 5px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 20px;
}
.search_input::placeholder {
  font-family: var(--font);
  font-size: var(--size-14);
}
.dropdown-options {
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
}
.dropdown-option {
  padding: 10px;
  cursor: pointer;
  color: #333;
  background-color: #fff;
  transition: background-color 0.2s ease;
}
.dropdown-option:hover {
  background-color: #f0f0f0;
}
.dropdown-option.active {
  background-color: #e0e0e0;
  font-weight: bold;
}

.tab_btn {
  padding: 4px 8px;
  border: 1px solid var(--color-black);
  border-radius: 15px;
  background-color: var(--color-white);
  font-family: var(--font);
  font-size: var(--size-12);
  color: var(--color-black);
  font-weight: var(--medium);
  margin-right: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab_btn.active {
  background-color: var(--color-black);
  color: var(--color-white);
}

.slider_container-line {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-dark-grey);
}
.slider_line-title {
  font-size: var(--size-22);
  font-weight: var(--semi-bold);
  color: var(--color-dark);
  margin-bottom: 30px;
  margin-top: 40px;
}
.card_product-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 30px;
  border-right: 1px solid var(--color-bockground-grey);
}
.image_card-line {
  width: 130px;
  height: 130px;
  object-fit: cover;
}
.title_card-line {
  text-align: center;
  margin-top: 20px;
  font-size: var(--size-14);
  font-weight: var(--medium);
  color: var(--color-dark);
}

@media screen and (max-width: 450px) {
  .slider-card {
    position: relative;
    max-width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--color-grey);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
  }
}