.footer_container {
    padding: 3px;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: var(--color-white);
    margin-bottom: 20px;
    margin-top: 40px;
}
.inner_footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: inset rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    box-sizing: border-box;
}
.logo-link-f {
    font-size: var(--size-18);
    font-weight: var(--semi-bold);
    color: var(--color-dark);
}
.footer__col {
    display: flex;
    flex-direction: column;
    width: 200px;
}
.col__title {
    font-size: var(--size-16);
    font-weight: var(--medium);
    color: var(--color-dark);
    margin-bottom: 30px;
}
.soc__link {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: var(--size-14);
}
.soc__link img {
    margin-right: 10px;
}
.footer_item-tel {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.tel__link-footer {
    margin-bottom: 6px;
    font-size: var(--size-14);
}
.tel__link-footer img {
    margin-right: 12px;
}
.col__descr {
    font-size: var(--size-14);
    margin-bottom: 10px;
}
.col__descr-last {
    font-size: var(--size-14);
}

@media screen and (max-width: 846px) {
    .inner_footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .col__title {
        margin-top: 30px;
    }
}